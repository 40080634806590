import { getStore } from '../store/store';
export const config = {
  store(): any {
    return getStore().getState();
  },
  CATEGORYRSA: 'RSA',
  CATEGORYRIDE: 'RIDE',
  CATEGORYSTORAGE: 'STORAGE',
  ENTITY: {
    CALL_CENTER: 'call-center',
    PARTNER: 'partner',
    PROVIDER: 'provider',
    NETWORK: 'network',
    FACILITY: 'facility',
    SYSTEM: 'system',
    MTT: 'MTT',
    EXTERNAL_FORM: 'external-form',
    FLEET_FORM: 'fleet-form',
    ROADSIDE_FORM: 'roadside-form',
    SECONDARY_FORM: 'secondary-form',
    EXTERNAL_VIEW: 'external-view',
  },
  NEW_AUDIT_EVENT: false,
  CUSTOMERTYPE_POLICY_HOLDER: 'POLICY_HOLDER',
  CUSTOMERTYPE_ON_DEMAND: 'ON_DEMAND',
  CUSTOMERTYPE_PRE_QUALIFIED_BY_PARTNER: 'PRE_QUALIFIED_BY_PARTNER',
  ADMIN: 'ROLE_ADMIN',
  REGIONALMGR: 'ROLE_REGIONAL_MANAGER',
  SUPERVISOR: 'ROLE_CSR_ROOT',
  CATEGORYTOW: 'TOW',
  CSR: 'ROLE_CSR',
  CUSTADMIN: 'ROLE_ADMIN_CUSTOMER_PARTNER',
  CUSTREGIONALMGR: 'ROLE_REGIONAL_MANAGER_CUSTOMER_PARTNER',
  CUSTSUPERVISOR: 'ROLE_CSR_ROOT_CUSTOMER_PARTNER',
  CUSTCSR: 'ROLE_CSR_CUSTOMER_PARTNER',
  DISPATCH: 'ROLE_DISPATCH',
  FACILITYDISPATCHMGR: 'ROLE_FACILITY_DISPATCH_MANAGER',
  FACILITYDISPATCHEMP: 'ROLE_FACILITY_DISPATCH_EMPLOYEE',
  CUSTFACILITYMGR: 'ROLE_CUSTOMER_PARTNER_FACILITY_MANAGER',
  CUSTFACILITYEMP: 'ROLE_FACILITY_EMPLOYEE',
  CUSTFACILITYADMIN: 'ROLE_CUSTOMER_PARTNER_FACILITY_ADMIN',
  CUST_PARTNER_SPECIALIST: 'ROLE_CUSTOMER_PARTNER_SPECIALIST',
  CHAT_DISPATCH: 'DISPATCH',
  CALL_CENTER: 'CALL_CENTER',
  CUSTOMER_PARTNER: 'CUSTOMER_PARTNER',
  FACILITY_CSR: 'FACILITY_CSR',
  BUILD_VERSION: process.env.REACT_APP_BUILD_VERSION,
  API_BUILD_VERSION: process.env.REACT_APP_API_BUILD_VERSION,
  STMID: 2050,
  SECONDARY_TOW: false,
  MAX_PHONE_LENGTH: 14,
  VIN_DECODE: false,
  BILLING_CENTER_ID_MAX_LENGTH: 4,
  BMW_PARTNER_LIST: ['bmw.bmw', 'bmw.mini', 'bmw.motorrad', 'bmw.rollsroyce'],
  MBUSA: 1019,
  TABLEAU: 'https://online.tableau.com',
  EVENT_HEADER: 'case-form',
};
