import React from 'react';
import { Form, Row, Col, Alert } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { useDropzone } from 'react-dropzone';

import { sendGAEvent, VIRUS_WAS_DETECTED } from '../../utils/ga';
import {
  activeCaseIdSelector,
  activeJobIdSelector,
  getAuthData,
} from '../../saga/login/loginSelector';
import { CustomModal, Button, TextInput } from '../../urgently-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUpload,
  faTimesCircle,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';
import { faFile as faFileLight } from '@fortawesome/free-regular-svg-icons';
import { config as CONFIG } from '../../utils/config';
import {
  JOB_REPORTS_POST_UPLOAD_DOCUMENT_API,
  ADD_CASE_POST_UPLOAD_DOCUMENT_API,
} from '../../constants/API';
import { usePrevious } from '../../hooks/usePrevious';

import styles from './attachDocumentModal.module.scss';

const MAX_DOC_SIZE = 1024 * 1024 * 10;
let modalHeight = 158;
const ALLOWED_FILE_EXTENSIONS = [
  'csv',
  'xlsx',
  'xls',
  'doc',
  'docx',
  'odt',
  'rtf',
  'txt',
  'jpg',
  'jpeg',
  'gif',
  'bmp',
  'png',
  'pdf',
  'flv',
  'avi',
  'mov',
  'mp4',
  'mpg',
  'wmv',
  '3gp',
];

interface Props {
  closeModal: () => void;
  resizeParentIframe: (size: number) => void;
  onSendFile: (file: any) => void;
  onAngularFilesRefresh: () => void;
}

const initialState = {
  files: [],
  name: '',
  description: '',
};

interface FileType {
  file?: any;
  name: string;
  description?: string;
  malware: boolean;
  uploaded: boolean;
}

type FileUpload = {
  files: FileType[];
  name: string;
  description: string;
};

const filesMap = new Map();
const filesNameMap = new Map();

const AttachDocuments: React.FC<Props> = ({
  closeModal,
  resizeParentIframe,
  onSendFile,
  onAngularFilesRefresh,
}) => {
  const [upload, setUpload] = React.useState<FileUpload>(initialState);
  const [uploadErrors, setUploadErrors] = React.useState<string[]>([]);
  const [alert, setAlert] = React.useState(false);
  const [progressPercentage, setProgressPercentage] = React.useState<any>({});
  const [cancelTokens, setCancelTokens] = React.useState<any>({});
  const [uploadedFiles, setUploadedFiles] = React.useState<{}[]>([]);

  const activeCaseId = useSelector(activeCaseIdSelector);
  const activeJobId = useSelector(activeJobIdSelector);
  const { authToken, isAddCase } = useSelector(getAuthData);

  const multipartPostUpload = React.useCallback(
    (url: string, data: any, coi?: any): any => {
      const header = {
        'auth-token': authToken,
        'Content-Type': undefined,
        Product: CONFIG.EVENT_HEADER,
      };
      const config = {
        headers: header,
        onUploadProgress: function (progressEvent: any) {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setProgressPercentage((prev: any) => {
            return {
              ...prev,
              [data.files.name]: percentCompleted,
            };
          });
        },
      };
      const json = JSON.stringify(data.model);
      const payload = new Blob([json], { type: 'application/json' });
      const transformRequest = new FormData();
      transformRequest.append('file', data.files);
      if (coi) {
        transformRequest.append('coi', payload);
      } else {
        transformRequest.append('meta', payload);
      }

      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      setCancelTokens((prev: any) => {
        return {
          ...prev,
          [data.files.name]: source,
        };
      });

      return axios({
        method: 'post',
        url: url,
        headers: config.headers,
        data: transformRequest,
        onUploadProgress: config.onUploadProgress,
        cancelToken: source.token,
      });
    },
    [authToken]
  );

  const uploadFile = React.useCallback(
    (
      file: any,
      name: string,
      notes: string,
      caseId?: number,
      jobId?: any
    ): any => {
      const payload: any = {
        name,
        notes,
      };
      if (caseId) {
        payload.caseId = caseId;
      }
      if (jobId) {
        payload.jobId = jobId;
      }
      payload.documentType = 'CSS_DOCUMENT';
      const data = { model: payload, files: file };
      const url = isAddCase
        ? ADD_CASE_POST_UPLOAD_DOCUMENT_API
        : JOB_REPORTS_POST_UPLOAD_DOCUMENT_API;
      return multipartPostUpload(url, data);
    },
    [isAddCase, multipartPostUpload]
  );

  const handleFileAttach = React.useCallback((): void => {
    const filteredFiles = upload.files.filter((f: FileType) => !f.uploaded);
    const totalLength = filteredFiles.length;
    if (totalLength === 0) {
      closeModal();
    }
    let countUpload = 0;
    const fileUpload: any = [];
    const { name, description: notes } = upload;
    upload.files.forEach((f: any) => {
      if (filesNameMap.has(f.name)) {
        return;
      }
      if (f.name) {
        if (activeCaseId) {
          if (activeJobId) {
            fileUpload.push(
              uploadFile(f, name, notes, activeCaseId, activeJobId)
            );
          } else {
            fileUpload.push(uploadFile(f, name, notes, activeCaseId));
          }
        } else if (isAddCase) {
          fileUpload.push(uploadFile(f, name, notes));
        }
      }
    });
    fileUpload.forEach((fileUploadPromise: any, idx: number) => {
      const uploadedFileName = upload.files[idx].name;
      fileUploadPromise
        .then(function (response: any) {
          if (!response.data?.data[0]) {
            setUpload(initialState);
            setUploadErrors(['Uploading error, please try after some time.']);
            return;
          }
          setCancelTokens((prev: any) =>
            Object.fromEntries(
              Object.entries(prev).filter(
                ([fileName]) => fileName !== uploadedFileName
              )
            )
          );
          filesNameMap.set(uploadedFileName, '');
          setUploadedFiles((prev: any) => [...prev, uploadedFileName]);
          setUpload((prev) => {
            return {
              ...prev,
              files: prev.files
                .map((file) => {
                  if (file.name === uploadedFileName) {
                    file.malware = !isAddCase
                      ? response.data.data[0].malware
                      : false;
                    return file;
                  } else {
                    return file;
                  }
                })
                .sort((a: any, b: any) => b.malware - a.malware),
            };
          });
          if (response.data.data[0].malware) {
            sendGAEvent(VIRUS_WAS_DETECTED);
            filesNameMap.set(uploadedFileName, 'virus');
          }
          countUpload++;
          if (countUpload === totalLength) {
            filesMap.clear();
            onAngularFilesRefresh();
            if (upload.files.some((el) => el.malware)) {
              setUpload((prev) => {
                return {
                  ...prev,
                  files: prev.files.filter((file: any) => file.malware),
                };
              });
              return;
            }
            isAddCase && onSendFile(upload.files[0]);
            setUpload(initialState);
            closeModal();
          }
        })
        .catch(function (err: any) {
          if (
            err.response?.data.error.includes(
              'contains malware,Unable to upload'
            )
          ) {
            const fileName = err.response.data.error.split(' ')[1];
            setUploadErrors([]);
            setUpload((prev) => {
              return {
                ...prev,
                files: prev.files.map((file) => {
                  if (file.name === fileName) {
                    file.malware = true;
                    return file;
                  } else {
                    return file;
                  }
                }),
              };
            });
          } else {
            const errorMessage = err.response?.data
              ? err.response.data.error
              : 'Uploading error, please, try after some time.';
            setUploadErrors([axios.isCancel(err) ? err.message : errorMessage]);
          }
          setProgressPercentage(0);
        });
    });
  }, [
    upload,
    activeCaseId,
    isAddCase,
    activeJobId,
    uploadFile,
    onSendFile,
    onAngularFilesRefresh,
    closeModal,
  ]);

  const handleChange = React.useCallback((key: string, value: string): void => {
    setUpload((prev) => ({
      ...prev,
      [key]: value,
    }));
  }, []);

  const onDrop = React.useCallback(
    (files) => {
      if (!files[0]) return;
      setProgressPercentage(0);
      setUploadErrors([]);
      setUpload((prev) => {
        return {
          ...prev,
          files: [],
        };
      });
      files.forEach((file: any) => {
        if (file.size > MAX_DOC_SIZE) {
          setUploadErrors((prev) => [
            ...prev,
            `${file.name} — maximum 10MB file size allowed.`,
          ]);
          return;
        }
        filesMap.set(file.name, file);
      });
      if (isAddCase && files.length > 1) {
        setAlert(true);
        return;
      }
      setAlert(false);
      const uniqueFiles: FileType[] = [];
      for (const file of filesMap.values()) {
        uniqueFiles.push(file);
      }
      uniqueFiles.forEach((f: any) => {
        if (f.name && f.name.lastIndexOf('.') > 0) {
          const [fileExtension] = f.name.split('.').reverse();
          if (ALLOWED_FILE_EXTENSIONS.includes(fileExtension.toLowerCase())) {
            f.uploaded = filesNameMap.has(f.name);
            f.malware = filesNameMap.get(f.name) === 'virus';
            setUpload((prev: FileUpload) => ({
              ...prev,
              files: [...prev.files, f].sort(
                (a: any, b: any) => b.malware - a.malware
              ),
            }));
          }
        }
      });
    },
    [isAddCase]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  const handleRemoveFile = React.useCallback(
    (fileName: string): void => {
      if (Object.keys(cancelTokens).includes(fileName)) {
        cancelTokens[fileName].cancel(`Uploading cancelled by user.`);
      }
      filesMap.delete(fileName);
      setUpload((prev) => {
        return {
          ...prev,
          files: prev.files.filter((f) => f.name !== fileName),
        };
      });
    },
    [cancelTokens]
  );

  const handleClose = React.useCallback((): void => {
    closeModal();
    setUpload(initialState);
  }, [closeModal]);

  const preUploadFilesLength = usePrevious(upload.files.length);

  const closeErrorHandler = React.useCallback((idx: number) => {
    setUploadErrors((prev) => prev.filter((err, index) => index !== idx));
  }, []);

  React.useEffect(() => {
    const modal = document.querySelector('.modal-body');
    const newModalHeight = modal && modal.getBoundingClientRect().height;
    if (newModalHeight !== null && modalHeight === newModalHeight) return;
    if (newModalHeight !== null) {
      resizeParentIframe(newModalHeight - 158);
      modalHeight = newModalHeight;
    }
  }, [
    upload,
    resizeParentIframe,
    uploadErrors,
    preUploadFilesLength,
    handleRemoveFile,
    alert,
    onDrop,
    closeErrorHandler,
  ]);

  return (
    <CustomModal
      title="Attach Document"
      show={true}
      closeModal={handleClose}
      dialogClassName="modal-fullscreen"
      actionButtons={
        <>
          <Button
            variant="secondary"
            clx="btn_gap text-uppercase"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            clx="text-uppercase flex-grow-0 text-nowrap"
            onClick={handleFileAttach}
            disabled={!upload.name || upload.files.length === 0}
          >
            Attach
          </Button>
        </>
      }
    >
      {alert && (
        <Alert variant="warning" onClose={() => setAlert(false)} dismissible>
          Please drop one file at time.
        </Alert>
      )}
      {uploadErrors[0] &&
        uploadErrors.map((err: string, idx, array) => (
          <div
            key={idx}
            id="errorNotifacation"
            className={`${styles.file_upload_error} ${
              idx + 1 === array.length ? 'mb-3' : 'mb-1'
            } d-flex align-items-center justify-content-between`}
          >
            <FontAwesomeIcon icon={faExclamationTriangle} />
            <span>
              {err.includes('maximum 10MB file size allowed') &&
              err.split('—')[0].length > 18
                ? err.split('—')[0].slice(0, 18).toLowerCase() +
                  '...  —  ' +
                  err.split('—')[1]
                : err.toLowerCase()}
            </span>
            <div style={{ color: 'red' }} className={styles.close_icon}>
              <FontAwesomeIcon
                icon={faTimesCircle}
                onClick={() => closeErrorHandler(idx)}
              />
            </div>
          </div>
        ))}
      {upload.files.map((f, idx, array) => {
        const { name, malware } = f;
        const progress = progressPercentage[name]
          ? progressPercentage[name]
          : 0;
        return (
          <Row
            key={idx}
            className={`px-3 ${
              idx === array.length - 1 ? 'mb-3' : 'mb-1'
            } pb-0 d-flex align-items-center justify-content-between`}
          >
            {!malware ? (
              <>
                <div>
                  <FontAwesomeIcon icon={faFileLight} />
                  <span className="ml-2">
                    {name.length > 28
                      ? `${name.slice(0, 28).toLowerCase()}...`
                      : name.toLowerCase()}
                  </span>
                </div>
                <div className={`d-flex align-items-center ${styles.progress}`}>
                  {uploadedFiles.includes(name) ? (
                    <>
                      <span className="mr-2">Completed</span>
                      <div className={styles.close_icon}>
                        <FontAwesomeIcon
                          icon={faTimesCircle}
                          onClick={() => handleRemoveFile(name)}
                          data-testid={`close-preview-${idx}`}
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className={`${styles.progress_bar} mr-3`}
                        style={{
                          background: `linear-gradient(90deg, #02baf2 ${progress}%, #f1f1f1 ${progress}%)`,
                        }}
                      />
                      <div className={styles.close_icon}>
                        <FontAwesomeIcon
                          icon={faTimesCircle}
                          onClick={() => handleRemoveFile(name)}
                          data-testid={`close-preview-${idx}`}
                        />
                      </div>
                    </>
                  )}
                </div>
              </>
            ) : (
              <div
                className={`${styles.file_upload_error} mb-1 d-flex align-items-center justify-content-between`}
              >
                <FontAwesomeIcon icon={faExclamationTriangle} />
                <span>
                  {(name.length > 12
                    ? `${name.slice(0, 12).toLowerCase()}...`
                    : name.toLowerCase()) +
                    ' cannot be uploaded, because we have detected a virus.'}
                </span>
                <div style={{ color: 'red' }} className={styles.close_icon}>
                  <FontAwesomeIcon
                    icon={faTimesCircle}
                    onClick={() => handleRemoveFile(name)}
                    data-testid="close-preview"
                  />
                </div>
              </div>
            )}
          </Row>
        );
      })}

      <Form className={`custom_form ${styles.attach_document_form}`}>
        <Row className="pl15">
          <Col sm={5} className={styles.drop_area} {...getRootProps()}>
            <div className={styles.upload_icon}>
              <FontAwesomeIcon icon={faUpload} />
            </div>
            <h3>
              Drop file to attach
              <br /> or
              <label htmlFor="file-input" className={styles.file_input}>
                browse
                <input
                  data-testid="drop-input"
                  multiple={!isAddCase}
                  {...getInputProps()}
                />
              </label>
            </h3>
            <span>Maximum upload file size: 10MB</span>
          </Col>
          <Col sm={7}>
            <TextInput
              name="name"
              value={upload.name}
              placeholder="*Name"
              clx="mb10"
              onChange={handleChange}
              isSyncUpdate
            />
            <TextInput
              name="description"
              textarea
              value={upload.description}
              placeholder="Description (optional)"
              clx="mb-0"
              inputClx={`max_height_80 ${styles.file_description}`}
              onChange={handleChange}
            />
          </Col>
        </Row>
      </Form>
    </CustomModal>
  );
};

export default AttachDocuments;
