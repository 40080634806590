import ReactGA from 'react-ga';
let initialized: any = null;

function initializeGA(): void {
  if (!initialized) {
    const GACode: any = process.env.REACT_APP_GACode;
    initialized = ReactGA.initialize(GACode);
  }
}

export function sendGAEvent(evtName?: string): void {
  if (process.env.REACT_APP_GA_ENABLED === 'true') {
    initializeGA();
    const re = /\s/g;
    let screenName = 'Reports';
    if (evtName && evtName.trim() !== '') {
      if (evtName.length > 0) {
        evtName = evtName.replace(re, '-');
        screenName = `${screenName}#${evtName}`;
      }
    }
    ReactGA.pageview(screenName);
  }
}

export const VIRUS_WAS_DETECTED = 'VIRUS_WAS_DETECTED';
