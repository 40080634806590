import * as React from 'react';
import './loader.scss';

export interface Props {
  show: boolean;
  spinner?: any;
  className?: string;
}

export const Loader: React.FC<Props> = (props) => {
  const { show, spinner, className } = props;
  return (
    <div
      className={`loader ${show ? `show` : `hide`} ${className}`}
      data-testid="loader"
    >
      {spinner ? (
        <img src={spinner} title="" alt="Loading..." />
      ) : (
        <div className="loader_custom" data-testid="custom-loader" />
      )}
    </div>
  );
};

export default Loader;
