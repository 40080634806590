import React from 'react';

interface State {
  error: any;
  errorInfo: any;
  hasError: boolean;
}

interface Props {}

export class ErrorBoundary extends React.Component<Props, State> {
  state = { error: null, errorInfo: null, hasError: false };
  // eslint-disable-next-line
  static getDerivedStateFromError(error: any): object {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  //     componentDidCatch(error: any, errorInfo: any): void {
  //     // You can also log the error to an error reporting service
  //     // logErrorToMyService(error, errorInfo);
  //     this.setState({
  //       error: error,
  //       errorInfo: errorInfo
  //     });
  //   }

  render(): React.ReactNode {
    if (this.state.hasError) {
      return <h1>Error Boundary.!</h1>;
    }

    return this.props.children;
  }
}
