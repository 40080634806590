import { createSelector } from 'reselect';

import {
  InitialAppStateTypes,
  AuthInitialStateTypes,
  AuthTypes,
} from '../../types/reducerTypes';

const getAuth = (state: InitialAppStateTypes): AuthInitialStateTypes =>
  state.authentication;

export const getAuthData = createSelector(
  getAuth,
  (auth): AuthTypes => auth.authData
);
export const activeCaseIdSelector = createSelector(
  getAuthData,
  (auth): number | undefined => auth.activeCaseId
);
export const isAddCaseSelector = createSelector(
  getAuthData,
  (auth): boolean | undefined => auth.isAddCase
);
export const activeJobIdSelector = createSelector(
  getAuthData,
  (auth): number | undefined => auth.activeJobId
);
