import { authenticationReducer } from '../saga/login/loginReducers';
import { configReducer } from '../saga/config/configReducer';
import { combineReducers } from 'redux';

export const appReducer = combineReducers({
  authentication: authenticationReducer,
  config: configReducer,
});

export const rootReducer = (state: any, action: any): any => {
  return appReducer(state, action);
};
