import React from 'react';
import { withRouter } from 'react-router';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import AttachDocuments from '../components/attachDocuments';
import { ANGULAR_AUTH_SUCCESS } from '../saga/login/loginActionTypes';
import { printLog } from '../utils/logging';
import {
  AuthInitialStateTypes,
  ConfigInitialStateTypes,
} from '../types/reducerTypes';
import {
  EventName,
  ScreenName,
  Events,
  sendMessage,
} from '../utils/eventHandler';

interface Props {
  config: ConfigInitialStateTypes;
  authentication: AuthInitialStateTypes;
  setAuth: (Object: any) => void;
}

class AttachDocumentsContainer extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.receiveMessage = this.receiveMessage.bind(this);
  }

  componentDidMount(): void {
    /* TODO: Receiving blank event on did mount that need to check */
    Events.on(EventName.ANGULAR_INIT_DATA, this.receiveMessage);
    sendMessage({
      type: EventName.REACT_INIT_FILE_UPLOAD,
      screen: ScreenName.FILE_UPLOAD_SCREEN,
      data: {},
    });
  }

  receiveMessage = function (this: any, event: any): void {
    try {
      if (event) {
        this.props.setAuth(event);
      }
    } catch (error) {
      printLog(error);
    }
  };

  sendFile = function (file: any): void {
    sendMessage({
      type: EventName.REACT_SEND_FILE,
      screen: ScreenName.ADD_DOCUMENT_SCREEN,
      data: { file },
    });
  };

  refreshFilesInAngular = function (): void {
    sendMessage({
      type: EventName.REACT_SENT_FILES_TO_SERVER,
      screen: ScreenName.ADD_DOCUMENT_SCREEN,
      data: {},
    });
  };

  closeModal = function (): void {
    sendMessage({
      type: EventName.CLOSE_ATTACH_DOCUMENT_MODAL,
      screen: ScreenName.ADD_DOCUMENT_SCREEN,
      data: {},
    });
  };

  handleResizeParentIframe = function (size: number): void {
    sendMessage({
      type: EventName.RESIZE_ATTACH_DOCUMENT_MODAL,
      screen: ScreenName.ADD_DOCUMENT_SCREEN,
      data: { size },
    });
  };

  render(): React.ReactNode {
    return (
      <AttachDocuments
        closeModal={this.closeModal}
        resizeParentIframe={this.handleResizeParentIframe}
        onSendFile={this.sendFile}
        onAngularFilesRefresh={this.refreshFilesInAngular}
      />
    );
  }
}

const mapStateToProps = (props: Props): object => {
  return {
    config: props.config,
    authentication: props.authentication,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): object => {
  return {
    setAuth: (params: any) => {
      dispatch({ type: ANGULAR_AUTH_SUCCESS, params });
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AttachDocumentsContainer) as any
);
