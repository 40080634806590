export const HOSTURL = process.env.REACT_APP_API_HOST;
export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
export const API_VERSION = process.env.REACT_APP_API_VERSION;
export const INDUSTRY = process.env.REACT_APP_INDUSTRY;
export const DEVICE = process.env.REACT_APP_DEVICE;
export const APP_TYPE = process.env.REACT_APP_APP_TYPE;
export const HTTP_POST = `POST`;
export const HTTP_PUT = `PUT`;
export const HTTP_GET = `GET`;

export const GET_JOB_DETAIL_API = (jobId: number): string =>
  `${HOSTURL}//ops/jobs/${jobId}`;
export const GET_JOB_DETAIL_AUDIT_EVENTS_API = (jobId: number): string =>
  `${HOSTURL}/ops/jobs/${jobId}/audit-events`;
export const SEARCH_JOB_REPORTS_API = (searchData: any): string => {
  return `${HOSTURL}/ops/reports/jobs/search?query=${searchData.searchValue}&limit=${searchData.limit}&offset=${searchData.offset}`;
};
export const FILTER_JOB_REPORTS_API = (searchData: any): string => {
  return `${HOSTURL}/ops/reports/jobs/filter?limit=${searchData.limit}&offset=${searchData.offset}&timeZone=${searchData.timeZone}&partnerId=${searchData.partnerId}`;
};
export const GET_SERVICES_LIST_API = (): string =>
  `${HOSTURL}/ops/configs/services`;
export const GET_JOB_INSPECTION_INFO_API = (
  jobId: number,
  inspectionType: string
): string =>
  `${HOSTURL}/ops/jobs/${jobId}/job-inspections?jobInspectionType=${inspectionType}`;
export const EXPORT_CSV_API = (
  startDate: string,
  endDate: string,
  timeZone: string,
  email: string,
  partnerId: number
): string =>
  `${HOSTURL}/ops/reports/jobs/generate?startDate=${startDate}&endDate=${endDate}&timeZone=${timeZone}&email=${email}&partnerId=${partnerId}`;
export const CASE_DOWNLOAD_RECEIPTS_API = (caseId: number): string =>
  `${HOSTURL}/ops/cases/${caseId}/receipt`;

export const JOB_REPORTS_POST_UPLOAD_DOCUMENT_API = `${HOSTURL}/ops/documents`;
export const ADD_CASE_POST_UPLOAD_DOCUMENT_API = `${HOSTURL}/ops/fileanalysis/scan`;
