import React from 'react';
import { Form } from 'react-bootstrap';

import './textInput.scss';

export interface Props {
  type?: string;
  value: string;
  name: string;
  onChange: (key: string, value: string) => void;
  validateKeyPress?: (e: any) => void;
  label?: string;
  labelBehavior?: 'static' | 'fade';
  placeholder?: string;
  clx?: string;
  inputClx?: string;
  error?: any;
  isRequired?: boolean;
  isSyncUpdate?: boolean;
  textarea?: boolean;
  maxLength?: number;
  disabled?: boolean;
  children?: any;
}

export const TextInput = React.forwardRef<HTMLInputElement, Props>(
  (props, outerRef?: any) => {
    const {
      type = 'text',
      value,
      name,
      onChange,
      validateKeyPress,
      label,
      labelBehavior = 'static',
      placeholder,
      clx,
      inputClx,
      error,
      isRequired,
      isSyncUpdate,
      textarea,
      maxLength,
      disabled,
      children,
    } = props;

    const [val, setVal] = React.useState<string>('');
    const [touched, setTouched] = React.useState<boolean>(false);
    const [isError, setIsError] = React.useState(false);

    const innerRef = React.useRef<HTMLInputElement>(null);
    const ref = outerRef || innerRef;

    const handleBlur = React.useCallback(
      (e: any): void => {
        setTouched(true);
        const { value } = e.target;
        onChange(name, value);
      },
      [onChange, name]
    );

    const handleChange = React.useCallback(
      (e: any): void => {
        const { value } = e.target;
        if (maxLength && value.length > maxLength) return;
        if (isSyncUpdate) {
          onChange(name, value);
          setVal(value);
        } else {
          setVal(value);
        }
      },
      [maxLength, isSyncUpdate, onChange, name]
    );

    React.useEffect(() => {
      setVal(value);
    }, [value]);

    React.useEffect(() => {
      if ((isRequired && touched && !val) || (touched && error)) {
        setIsError(true);
      } else {
        setIsError(false);
      }
    }, [isRequired, touched, val, error]);

    React.useEffect(() => {
      return () => setTouched(false);
    }, []);

    const getInputStyles = React.useMemo((): string => {
      let styles = inputClx ?? '';
      if (labelBehavior === 'fade') {
        if (val) {
          styles += ' pt-2 pb-0';
        }
      }
      return styles;
    }, [inputClx, labelBehavior, val]);

    const getLabelStyles = React.useMemo((): string => {
      let styles = '';
      if (labelBehavior === 'fade') {
        styles += ' inner_label';
        if (!val) {
          styles += ' inner_label_hidden';
        }
      }
      return styles;
    }, [labelBehavior, val]);

    return (
      <Form.Group className={clx}>
        {label && (
          <Form.Label className={getLabelStyles} htmlFor={name}>
            {label}
          </Form.Label>
        )}
        <Form.Control
          ref={ref}
          id={name}
          type={type}
          as={textarea ? 'textarea' : 'input'}
          placeholder={
            labelBehavior === 'static'
              ? placeholder || ''
              : placeholder || label
          }
          className={`${getInputStyles} ${isError ? ' missing_field' : ''}`}
          value={val}
          maxLength={maxLength}
          onChange={handleChange}
          onBlur={handleBlur}
          onKeyPress={(e: any) => (validateKeyPress ? validateKeyPress(e) : e)}
          disabled={disabled}
          data-testid={name}
          data-cy={name}
        />
        {children}
        {isError && (
          <p className="error">{!val ? 'Please enter a value.' : error}</p>
        )}
      </Form.Group>
    );
  }
);
