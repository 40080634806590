//import { printLog } from './logging';
import { object, string, is } from 'superstruct';

const MessagePayloadSuperStruct = object({
  type: string(),
  screen: string(),
  data: object(),
});

interface MessagePayload {
  type: string;
  screen: string;
  data: any;
}
interface CallbackType {
  (params: any): void;
}

export const EventName = {
  ANGULAR_INIT_DATA: 'ANGULAR_INIT_DATA',
  REACT_INIT_REPORTS_EVENT: 'REACT_INIT_REPORTS_EVENT',
  ANGULAR_SHOW_ERROR: 'ANGULAR_SHOW_ERROR',
  DOWNLOAD_RECEIPT_MESSAGE: 'DOWNLOAD_RECEIPT_MESSAGE',
  CLOSE_ATTACH_DOCUMENT_MODAL: 'CLOSE_ATTACH_DOCUMENT_MODAL',
  REACT_INIT_FILE_UPLOAD: 'REACT_INIT_FILE_UPLOAD',
  RESIZE_ATTACH_DOCUMENT_MODAL: 'RESIZE_ATTACH_DOCUMENT_MODAL',
  REACT_SEND_FILE: 'REACT_SEND_FILE',
  REACT_SENT_FILES_TO_SERVER: 'REACT_SENT_FILES_TO_SERVER',
};

export const ScreenName = {
  REPORTS_SCREEN: `Reports Screen`,
  ADD_DOCUMENT_SCREEN: `Add Document Screen`,
  FILE_UPLOAD_SCREEN: 'File Upload Screen',
};

export const postMessage: any = {
  type: EventName.REACT_INIT_REPORTS_EVENT,
  screen: ScreenName.REPORTS_SCREEN,
  data: {},
};

const Callbacks = {} as any;
export const Events = {
  on: (at: string, callback: CallbackType): any => {
    return Events.listen(at, callback);
  },
  listen: (at: string, callback: CallbackType): any => {
    /*eslint-disable eqeqeq*/
    if (at == '') {
      return false;
    }
    Callbacks[at] = callback;
  },
  t: (at: string, data: any): any => {
    return Events.trigger(at, data);
  },
  trigger: (at: string, data: any): any => {
    data = data || '';
    const method = Callbacks[at];
    method(data);
  },
  rm: (at: string): any => {
    return Events.remove(at);
  },
  remove: (at: string): any => {
    delete Callbacks[at];
  },
  removeAll: (at: string): any => {
    delete Callbacks[at];
  },
};

const isSecureRequest = (message: MessagePayload): any => {
  return is(message, MessagePayloadSuperStruct);
};

export function sendMessage(message: MessagePayload): void {
  if (document.referrer) {
    window.parent.postMessage(
      JSON.stringify(message),
      `${document.referrer.slice(0, -1)}`
    );
  }
}
/*NOTE: Main post message listner. */
window.addEventListener(
  'message',
  function (message: any) {
    if (message.data && typeof message.data === 'string') {
      const receivedMessage = JSON.parse(message.data);
      if (message.origin === document.referrer.slice(0, -1)) {
        switch (receivedMessage.type) {
          case EventName.ANGULAR_INIT_DATA:
            if (isSecureRequest(receivedMessage)) {
              Events.trigger(EventName.ANGULAR_INIT_DATA, receivedMessage.data);
            }
            break;
          case EventName.ANGULAR_SHOW_ERROR:
            Events.trigger(EventName.ANGULAR_INIT_DATA, receivedMessage.data);
            break;
          default:
            break;
        }
      }
    }
  },
  false
);
